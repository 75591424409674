import {Elm} from './elm/Main.elm'

document.addEventListener("DOMContentLoaded", function () {
    const identity = {
        clientId: getClientId(),
        name: getName()
    };

    let app = Elm.Main.init({flags: identity})
    let socket = null;

    app.ports.wsConnect.subscribe(function (info) {
        if (socket !== null) {
            socket.close();
        }

        saveName(info.identity.name);

        const url = `${process.env.SERVER_ROOT_URL}/lobby/${info.lobbyId}?clientId=${info.identity.clientId}&name=${info.identity.name}`;
        socket = new WebSocket(url);

        socket.addEventListener("message", function (event) {
            const msg = JSON.parse(event.data);
            app.ports.messageReceiver.send(msg);
        });
    });

    app.ports.sendMessage.subscribe(function (message) {
        if (socket === null) {
            log.error("Not connected to server, cannot send message");
            return;
        }

        socket.send(JSON.stringify(message));
    });
});

function getClientId() {
    let id = window.localStorage.getItem("clientId")
    if (id === null) {
        id = uuidv4();
        window.localStorage.setItem("clientId", id);
    }

    return id;
}

function getName() {
    let name = window.localStorage.getItem("name")
    if (name === null) {
        return ""
    }

    return name;
}

function saveName(name) {
    window.localStorage.setItem("name", name)
}

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
